<template>
  <div class="course-container">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>课程管理</BreadcrumbItem>
      <BreadcrumbItem
        >课程列表<span style="margin-left: 12px">{{ this.postId === 0 ? '' : '(' + this.showName + ')' }}</span></BreadcrumbItem
      >
    </Breadcrumb>
    <div style="width: 100%;display: flex;margin-top: 10px;height: calc(100% - 28px)">
      <div class="left" v-if="isShow === true" v-show="showbody">
        <div class="top" style="display: flex;justify-content: space-between">
          <div style="display: flex">
            <div @click="Show" class="showTree">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shouqi" />
              </svg>
            </div>
            <span class="lesson">课程分类</span>
          </div>
          <div class="showMore" @click="showAll">
            <span>展开</span>
            <svg class="icon" aria-hidden="true" style="width: 12px">
              <use xlink:href="#icon-zhankai" />
            </svg>
          </div>
        </div>
        <div class="tree">
          <el-tree
            v-if="treeData.length !== 0"
            :expand-on-click-node="false"
            node-key="id"
            :default-checked-keys="[5]"
            @node-click="handleClick"
            :default-expanded-keys="defaultExpandKeys1"
            draggable
            ref="treeFirst"
            :data="treeData"
            :props="defaultProps"
            :highlight-current="true"
            @node-drop="handleDrop"
            :allow-drop="allowDrop"
          >
            <div
              class="custom-tree-node"
              style="width: 100%;display: flex;justify-content: space-between;height: 100%;line-height: 30px"
              node-key="data.id"
              slot-scope="{ node, data }"
              @mouseenter="addShowIt(data)"
              @mouseleave="leaveAdd"
            >
              <div @click="choosePost(data)" style="display: flex;width: 100%;height: 100%">
                <!--                  <div class="sendClass" v-if="data.status" @click="release(data)">-->
                <!--                    <svg class="icon" :style="data.status === 0 ? 'color: #568FEBFF' : 'color: #d2d2d2'"-->
                <!--                         aria-hidden="true">-->
                <!--                      <use xlink:href="#icon-fabu1"></use>-->
                <!--                    </svg>-->
                <!--                  </div>-->
                <!--                  <div style="height: 100%;" @click="release(data)" class="sendClass" v-else>-->
                <!--                    <svg class="icon" style="color: #568FEBFF" aria-hidden="true">-->
                <!--                      <use xlink:href="#icon-fabu1"></use>-->
                <!--                    </svg>-->
                <!--                  </div>-->
                <div>
                  <Tooltip :content="node.label" max-width="200" :transfer="true">
                    <div>
                      {{ node.label.length > 12 ? node.label.substring(0, 12) + '...' : node.label }}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div v-if="addShowId === data.id">
                <div v-if="data.id !== 0">
                  <Tooltip :transfer="true" content="新增">
                    <svg @click="addLesson(data)" class="icon" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0">
                      <use xlink:href="#icon-xinzeng" />
                    </svg>
                  </Tooltip>
                  <Tooltip :transfer="true" content="删除">
                    <svg @click="delClass(data)" class="icon" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0">
                      <use xlink:href="#icon-shanchu" />
                    </svg>
                  </Tooltip>
                  <Tooltip :transfer="true" content="修改">
                    <svg class="icon" @click="updataShow(data)" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0">
                      <use xlink:href="#icon-bianji3" />
                    </svg>
                  </Tooltip>
                </div>
              </div>
            </div>
          </el-tree>
        </div>
        <div class="addIt" style="margin-top: 20px" @click="isadd">
          <svg class="icon" aria-hidden="true" style="height: 16px;width: 16px;margin-right: 5px ">
            <use xlink:href="#icon-xinzeng" />
          </svg>
          新增分类
        </div>
      </div>
      <div id="rightContainer" style="width: 100%;overflow-y: auto;" :style="isShow && showbody ? 'margin-left: 10px;' : ''">
        <div style="display: flex;">
          <div style="width: 72px;margin-right: 20px;" v-if="isShow === false">
            <div @click="Show(1)">
              <div class="show">
                <div @mouseenter="isShowOut = true" @mouseleave="isShowOut = false" class="bg-shadow func">
                  <div v-if="!isShowOut" style="white-space: nowrap;">
                    <img src="../../../../assets/组 882.png" style="position: relative;right: 5px;top: 1px" height="10" width="6" />
                    <span style="color: #6a8abeff">分类</span>
                  </div>
                  <div v-if="isShowOut" style="white-space: nowrap">
                    <img src="../../../../assets/组 882 (1).png" style="position: relative;right: 5px;top: 1px" height="10" width="6" />
                    <span style="color: #3a4e64">分类</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%">
            <div class="bg-shadow func-bar">
              <div v-show="!showbody" style="display: flex;justify-content: space-between;width: 100%">
                <div style="display: flex">
                  <div @click="Show" class="showTree">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-shouqi" />
                    </svg>
                  </div>
                  <span class="lesson" style="line-height: 16px">课程分类</span>
                </div>
                <div style="margin-right: 50px;cursor: pointer" @click="exhibition">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-shouqi1" />
                  </svg>
                  收起
                </div>
              </div>
              <div class="button-container" style="display: flex" v-show="showbody === true">
                <Button class="filter-btn btn-shadow" type="primary" @click="addCourse = true">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-xinzeng" />
                  </svg>
                  新建课程
                </Button>
                <Button :disabled="selectIds.length === 0" class="filter-btn btn-shadow" type="primary" @click="handleDelCourses" v-if="isShowCard">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-shanchu" />
                  </svg>
                  删除
                </Button>
                <Button v-if="isShowCard" class="filter-btn btn-shadow" :disabled="itemData.length === 0" type="primary" @click="moveClass = true">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-move" />
                  </svg>
                  移动到分类
                </Button>
              </div>
              <div class="search-container" v-show="showbody === true">
                <Input
                  v-model="searchForm.name"
                  search
                  placeholder="请输入查找关键字"
                  style="margin-left: auto;width: 250px;margin-right: 20px;"
                  @on-search="searchByKeyword"
                  @on-focus="
                    searchForm = {
                      name: '',
                      beginTime: '',
                      endTime: '',
                      status: ''
                    }
                  "
                />
                <Icon type="ios-apps-outline" size="32" style="margin-right: 20px;cursor: pointer" @click="showCard" v-if="false" />
                <div class="showTable" @click="isShowCard = !isShowCard" v-if="!isShowCard" title="列表">
                  <img src="../../../../assets/组 884.png" height="16" width="16" />
                </div>
                <div class="showTable" @click="isShowCard = !isShowCard" v-if="isShowCard" title="视图">
                  <img src="../../../../assets/组 858.png" height="16" width="16" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-container bg-shadow" v-if="isShowCard" v-show="showbody === true">
          <div v-if="courseCard.total === 0" style="flex: auto;box-shadow:0px 0px 5px rgba(7,27,41,0.1)">
            <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
              <div>
                <img src="../../../../assets/暂无记录@2x.png" />
                <div style="color: #3A4E64;font-size: 14px;font-weight: 400;margin: 30px 0">
                  您还没有创建任何课程哦
                </div>
                <Button class="filter-btn btn-shadow" type="primary" @click="addCourse = true">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-xinzeng" />
                  </svg>
                  新建课程
                </Button>
              </div>
            </div>
          </div>
          <el-table
            v-if="courses.total !== 0"
            height="calc(100% - 73px)"
            :data="courseCard.data"
            :loading="loading"
            @selection-change="selectCourses"
            @row-click="gotoCourseDetail"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column label="课程名称" prop="name" />
            <el-table-column label="描述" prop="description" />
            <el-table-column label="创建者" prop="creatorName" />
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                <span style="font-size: 14px;color: #555555;">{{ $formatTime(scope.row.createTime, 'yyyy-MM-dd hh:mm') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope" slot="action">
                <div class="operation">
                  <Tooltip content="编辑" placement="top">
                    <JYIcon href="#icon-bianji" class="buttom" @click="handleEdit(scope.row)" />
                  </Tooltip>
                  <Tooltip content="删除" placement="top" style="margin: 0 10px">
                    <svg class="icon buttom" aria-hidden="true" v-on:click.stop="handleDelCourse(scope.row.courseId)" style="font-size: 12px">
                      <use xlink:href="#icon-shanchu" />
                    </svg>
                  </Tooltip>
                  <Tooltip content="绑定目标" placement="top">
                    <svg class="icon buttom" aria-hidden="true" style="font-size: 12px;transform: rotate(90deg)" @click.stop="bindTarget(scope.row)">
                      <use xlink:href="#icon-bangdingpng" />
                    </svg>
                  </Tooltip>
                  <Tooltip content="移动到分类" placement="top" style="margin-left: 10px">
                    <svg
                      class="icon buttom"
                      aria-hidden="true"
                      style="font-size: 12px;transform: rotate(90deg)"
                      @click.stop="classMoveItem(scope.row)"
                    >
                      <use xlink:href="#icon-paixu" />
                    </svg>
                  </Tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="page" v-if="courses.total !== 0">
            <div>
              <Page
                show-total
                show-sizer
                :page-size-opts="[10, 20, 40, 100]"
                show-elevator
                :total="courseCard.total"
                @on-change="changePage"
                @on-page-size-change="changePageSize"
                :current="pageNumber + 1"
              />
            </div>
          </div>
        </div>
        <div v-show="!showbody" class="bg-shadow largeTree" style="height: calc(100% - 66px);overflow-y: auto;">
          <div class="tree">
            <el-tree
              v-if="treeData.length !== 0"
              :expand-on-click-node="false"
              node-key="id"
              @node-click="handleClick"
              :default-expanded-keys="defaultExpandKeys1"
              draggable
              ref="tree"
              :data="treeData"
              :props="defaultProps"
              :highlight-current="true"
              @node-drop="handleDrop"
              :allow-drop="allowDrop"
            >
              <!--          @node-drag-over="handleDragOver"-->
              <div
                class="custom-tree-node"
                style="width: 100%;display: flex;justify-content: space-between"
                node-key="data.id"
                slot-scope="{ node, data }"
                @mouseenter="addShowIt(data)"
                @mouseleave="leaveAdd"
              >
                <div style="display: flex;width: 90%;">
                  <!--                  <div style="line-height: 85px;margin-left: 15px">-->
                  <!--                    <div class="sendClass" v-if="data.status === 1" @click="release(data)">-->
                  <!--                      <svg class="icon" style="color: #d2d2d2" aria-hidden="true">-->
                  <!--                        <use xlink:href="#icon-fabu1"/>-->
                  <!--                      </svg>-->
                  <!--                    </div>-->
                  <!--                    <div class="sendClass" v-if="data.status === 0" @click="release(data)">-->
                  <!--                      <svg class="icon" style="color: #568FEBFF" aria-hidden="true">-->
                  <!--                        <use xlink:href="#icon-fabu1"/>-->
                  <!--                      </svg>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div style="width: 100%;display: flex;">
                    <div v-if="data.imgUrl" style="margin-right: 50px;width: 152px;">
                      <img :src="data.imgUrl" alt="" style="height: 80px;width: 152px;border-radius:3px;" />
                    </div>
                    <div v-else style="margin-right: 50px;width: 152px;">
                      <img src="../../../../assets/c7ca690a48edafb62092278ea645b0b.jpg" height="80" width="152" />
                    </div>
                    <div class="nodeLable">
                      {{ node.label }}
                    </div>
                    <div style="line-height: 85px;font-size: 14px;color: #333333;width: 30%">
                      {{ data.description || '' }}
                    </div>
                    <!--                    <div v-else style="line-height: 85px;font-size: 14px;color: #333333;width: 30%"></div>-->
                    <div style="line-height: 85px;font-size: 14px;color: #333333;width: 120px">
                      {{ data.creatorName }}
                    </div>
                    <div v-if="data.title !== '所有课程'">
                      <div v-if="data.updateTime" style="line-height: 85px;font-size: 14px;color: #333333;width: 30%">
                        <span>{{ $formatTime(data.updateTime, 'yyyy-MM-dd hh:mm') }}</span>
                      </div>
                      <div v-else style="line-height: 85px;font-size: 14px;color: #333333;width: 30%">
                        <span>{{ $formatTime(data.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="addShowId === data.id" style="line-height: 85px;margin-right: 20px">
                  <div v-if="data.id !== 0">
                    <Tooltip :transfer="true" content="新增">
                      <svg @click="addLesson(data)" class="icon" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0">
                        <use xlink:href="#icon-xinzeng" />
                      </svg>
                    </Tooltip>
                    <Tooltip :transfer="true" content="删除">
                      <svg @click="delClass(data)" class="icon" aria-hidden="true" style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0">
                        <use xlink:href="#icon-shanchu" />
                      </svg>
                    </Tooltip>
                    <Tooltip :transfer="true" content="修改">
                      <svg
                        class="icon"
                        @click="updataShow(data)"
                        aria-hidden="true"
                        style="height: 14px;width: 16px;margin-right: 5px;color: #B0B0B0"
                      >
                        <use xlink:href="#icon-bianji3" />
                      </svg>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </el-tree>
            <div class="addIt" style="margin: 20px;float: left" @click="isadd">
              <svg class="icon" aria-hidden="true" style="height: 16px;width: 16px;margin-right: 5px ">
                <use xlink:href="#icon-xinzeng" />
              </svg>
              新增分类
            </div>
          </div>
        </div>
        <div class="scroll-container" v-if="!isShowCard" style="height: calc(100vh - 184px)" v-show="showbody === true">
          <Scroll :on-reach-bottom="handleReachBottom" :distance-to-edge="10" :loading-text="loadingText" ref="scroll">
            <div class="course-list" ref="courseList">
              <CourseCard
                :course="course"
                v-for="(course, index) in courseCard.data"
                :key="index"
                @on-edit="handleEdit(course)"
                @on-del="handleDelCourse(course.courseId)"
                :style="index !== 0 && (index + 1) % cardListSize === 0 ? 'margin-right: 0' : 'margin-right: 20px'"
              />
              <div class="top" v-if="backTop" @click="back">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-bottom" />
                </svg>
              </div>
            </div>
          </Scroll>
        </div>
      </div>
    </div>
    <ConfirmDialog
      :value="deleteVisible"
      :title="'删除课程'"
      :loading="deleteLoading"
      @on-ok="delCourse"
      @on-cancel="deleteClose"
      @on-cancel-end="afterDeleteClose"
    >
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        {{ deleteTitle }}
      </div>
    </ConfirmDialog>
    <!--    <DeleteModal v-model="deleteVisible" :title="`${deleteTitle}`" :loading="deleteLoading" @on-ok="delCourse">-->
    <!--      <div style="height: 1px"></div>-->
    <!--    </DeleteModal>-->
    <DeleteModal v-model="deleteClass" :title="`${deleteTitle}`" :loading="deleteLoading" @on-ok="delLesson">
      <div style="height: 1px"></div>
    </DeleteModal>
    <EditModal v-model="edit" :form="editForm" @on-reflash-list="searchByKeyword('')" />
    <el-dialog :visible="addCourse" title="新建课程" class="yt-dialog yt-dialog-default" @close="$refs.newCourse.cancel()">
      <NewCourse ref="newCourse" :postId="postId" @on-ok="getLesson" :itCategoryId="itCategoryId" />
    </el-dialog>
    <el-dialog
      :visible="showUpdata"
      :title="isAdd ? '添加分类' : '修改分类'"
      @close="addCancle"
      :close-on-click-modal="false"
      class="yt-dialog yt-dialog-default"
    >
      <Form ref="catForm" :model="classUpdata" :rules="updateClass" label-position="left" :label-width="132" class="yt-form yt-dialog-form">
        <FormItem label="名称" prop="className">
          <el-input maxlength="20" show-word-limit v-model="classUpdata.className" />
        </FormItem>
        <FormItem label="上传图片" prop="classUrl">
          <Upload
            :headers="coverType === 0 ? {} : token"
            :before-upload="beforeUpload"
            type="drag"
            :data="uploadParams"
            :action="actionUrl"
            v-if="classUpdata.classUrl === ''"
            v-model="classUpdata.classUrl"
            :format="['jpg', 'jpeg', 'png']"
            :on-success="uploadCoverSuccess"
            :on-progress="UploadImg"
          >
            <div style="display: flex;justify-content:center;height: 65px;align-items: center">
              <JYIcon href="#icon-shangchuan" style="color: #3399ff; font-size: 42px;margin-right: 12px" />
              <p>点击或拖放上传图片</p>
            </div>
          </Upload>
          <div class="imgUrl" v-if="classUpdata.classUrl">
            <img width="45px" height="45px" :src="classUpdata.classUrl" alt="" />
            <div v-if="isAdd === true" style="width: 100%;text-align: center;line-height: 37px">
              {{ imgName.length > 10 ? imgName.substr(0, 10) + '...' : imgName }}
            </div>
            <div v-else style="width: 100%;text-align: center;line-height: 37px">
              {{ updateImg.length > 10 ? updateImg.substr(0, 10) + '...' : updateImg }}
            </div>
            <div style="margin-right: 12px;line-height: 37px;cursor: pointer" @click="delImg">
              <svg class="icon" aria-hidden="true" font-size="8">
                <use xlink:href="#icon-guanbi1" />
              </svg>
            </div>
          </div>
        </FormItem>
        <FormItem class="describe" label="描述" prop="describe">
          <el-input v-model="classUpdata.describe" maxlength="200" show-word-limit type="textarea" placeholder="请输入描述" />
          <div style="color:#9EA0A2FF;font-size: 12px">不超过200个字</div>
        </FormItem>
        <!--        <FormItem label="状态">-->
        <!--          <RadioGroup v-model="classUpdata.state" @on-change="classState">-->
        <!--            <Radio :label="0">发布</Radio>-->
        <!--            <Radio :label="1">不发布</Radio>-->
        <!--          </RadioGroup>-->
        <!--        </FormItem>-->
      </Form>
      <div class="footer" slot="footer">
        <el-Button @click="addCancle" class="button">取消</el-Button>
        <el-Button type="primary" @click="addCategory" class="button" :loading="loading">确认</el-Button>
      </div>
    </el-dialog>
    <el-dialog :visible="moveClass" title="移动到分类" @close="moveCancel" class="yt-dialog yt-dialog-default">
      <div class="moveClass">
        <el-tree @node-click="lessonMove" :data="treeData" ref="tree" :props="defaultProps"> </el-tree>
      </div>
      <div class="footer">
        <Button @click="moveCancel" class="button">取消</Button>
        <Button type="primary" @click="classMove" class="button">确认</Button>
      </div>
    </el-dialog>
    <el-dialog :visible="showAddTarget" title="绑定目标" class="yt-dialog yt-dialog-medium" @close="cancleAdd">
      <div class="addTarget">
        <div style="width: 50%;height: 100%;overflow-y: auto;overflow-x: auto;border-right: 1px solid #E4E9EF">
          <CheckboxGroup v-model="targetVal" style="text-align: left">
            <Checkbox v-for="target in targetList" :key="target.id" :label="target.id" class="target-checkbox">
              {{ target.name }}
            </Checkbox>
          </CheckboxGroup>
        </div>
        <div style="width: 50%;height: 100%;overflow: auto">
          <div class="top">
            <span>已绑定目标</span>
            <Button type="primary" @click="clearKnowledge = true">清空</Button>
          </div>
          <div
            v-for="addList in targetLists"
            :key="addList.id"
            style="display: flex;align-items: center"
            @mouseenter="targetsEnter(addList)"
            @mouseleave="targetsEnterId = 0"
          >
            <div style="position: relative;left:12px;width: 8px;cursor: pointer">
              <svg v-if="targetsEnterId === addList.id" @click="deltarget(addList)" class="icon" aria-hidden="true" font-size="8">
                <use xlink:href="#icon-guanbi1"></use>
              </svg>
            </div>
            <div class="addList">{{ addList.name }}</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <Button @click="cancleAdd" class="button">取消</Button>
        <Button type="primary" @click="addCouresTargets" class="button">确认</Button>
      </div>
    </el-dialog>
    <ConfirmDialog
      :value="clearKnowledge"
      :title="'删除课程'"
      :loading="deleteLoading"
      @on-ok="clearStarget"
      @on-cancel="deleteClose"
      @on-cancel-end="afterDeleteClose"
    >
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认清空已绑定目标吗？
      </div>
    </ConfirmDialog>
    <DeleteModal v-model="clearKnowledge" :title="`确认清空已绑定目标吗？`" :loading="deleteLoading" @on-ok="clearStarget"> </DeleteModal>
    <DeleteModal v-model="delSingle" :title="delName" :loading="deleteLoading" @on-ok="clearSingleStarget"> </DeleteModal>
  </div>
</template>
<script>
import courseApi from '@api/course'
import DeleteModal from '@components/common/DeleteModal'
import CourseCard from './CourseCard'
import EditModal from './EditModal'
import JYIcon from '@components/common/JYIcon'
import NewCourse from '../course/NewCourse'
import TcVod from 'vod-js-sdk-v6'
import VideoAPI from '@api/video'
import ossApi from '@api/oss'
import config from '../../../../config/config'
import ConfirmDialog from '../../../common/dialog/ConfirmDialog'
import YTIcon from '../../../common/YTIcon'

export default {
  components: {
    NewCourse,
    EditModal,
    CourseCard,
    DeleteModal,
    JYIcon,
    TcVod,
    VideoAPI,
    ConfirmDialog,
    YTIcon
  },
  created() {
    if (this.$route.query.showbody === true) {
      this.isShow = this.$route.query.showbody
      this.choosePostId = this.$route.query.choosePostId
      this.defaultExpandKeys1 = this.$route.query.defaultExpandKeys1
      this.postId = this.$route.query.postId
      this.showName = this.$route.query.showName
    }
    this.showBackgrong = true
    this.tcVod = new TcVod({
      getSignature: VideoAPI.getVodUploadSignature
    })
    this.getCourseList()
    ossApi.upType().then(type => {
      this.coverType = type.res
    })
  },
  data() {
    return {
      token: {
        Authorization: 'Bearer ' + (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).access_token)
      },
      coverType: '',
      serveLoca: '',
      delSingle: false,
      delName: '',
      clearKnowledge: false,
      targetListId: 0,
      targetVal: [],
      addTarget: false,
      choosePostId: 0,
      showBackgrong: false,
      showIcon: false,
      imgUrl: '',
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      actionUrl: config.baseUrl + '/file/api/v1/upload/cover',
      treeStatus: 0,
      showbody: true,
      uploadProgress: 0,
      imageObject: {
        name: '',
        url: '',
        base64: '',
        file: null
      },
      updateClass: {
        className: [
          {
            required: true,
            message: '课程分类名称不能为空',
            trigger: 'blur'
          },
          { type: 'string', max: 20, min: 0, message: '名称不要超过20位字符' }
        ],
        describe: { required: true, message: '描述不可为空', trigger: 'blur' },
        classUrl: { required: true, message: '请上传图片', trigger: 'blur' },
        neWImg: {
          type: 'string',
          message: '图片不能为空'
        }
      },
      classUpdata: {
        className: '',
        classUrl: '',
        describe: '',
        state: 0
      },
      isAdd: false,
      itemData: '',
      moveClass: false,
      level: 0,
      expandedList: [],
      showName: '',
      updataClass: '',
      showItAdd: false,
      showUpdata: false,
      addShowId: 0,
      isaddClass: false,
      isShowOut: false,
      isShow: false,
      addCourse: false,
      treeData: [],
      searchType: '',
      isShowCard: false, // 默认卡片显示
      showManager: false,
      edit: false,
      deleteVisible: false,
      deleteClass: false,
      deleteTitle: '',
      deleteLoading: false,
      addModal: false,
      loading: false,
      loadingText: '加载中',
      backTop: false,
      tableHeader: [
        {
          type: 'selection',
          align: 'center',
          width: 65,
          className: 'selection-column'
        },
        {
          title: '课程名称',
          key: 'name',
          className: 'name-column',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '描述',
          key: 'description',
          className: 'description-column',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '创建者',
          key: 'creatorName',
          align: 'left',
          className: 'type-column',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '创建时间',
          slot: 'createTime',
          className: 'status-column'
        },
        {
          title: '操作',
          slot: 'action',
          className: 'action-column'
        }
      ],
      defaultProps: {
        children: 'categoryTreeVOS',
        label: 'title'
      },
      courses: {
        data: [],
        total: 0
      },
      imgName: '',
      updateImg: '',
      editForm: {},
      pageNumber: 0,
      pageSize: 10,
      searchForm: {
        name: '',
        beginTime: '',
        endTime: '',
        status: ''
      },
      searchVisible: false,
      delCourseIds: [],
      selectIds: [],
      searchKeyword: '',
      delStatus: '',
      courseCard: {
        total: 0,
        page: 1, // 这边得要从1开始，滚动分页默认显示第一页了
        size: 10,
        data: []
      },
      managerListHeight: window.innerHeight,
      courseId: [],
      managerId: '',
      courseManagerStatus: '',
      managerList: [],
      postId: 0,
      categoryId: 0,
      itCategoryId: 0,
      category: 0,
      parentId: 0,
      defaultExpandKeys1: [],
      patentId: 0, //父级id
      showAddTarget: false,
      targetList: [],
      targetCourseId: 0,
      targetId: [],
      targetLists: [],
      targetsEnterId: 0,
      delTargetId: 0,
      cardListSize: 0,
      cardListWidth: 0
    }
  },
  mounted() {
    window.onresize = () => {
      this.cardListWidth = this.$refs.courseList.clientWidth
    }
    this.resizeList(this.$refs.courseList.clientWidth)
  },
  inject: ['reload'],
  watch: {
    cardListWidth(val) {
      this.resizeList(val)
    },
    isShow(val) {
      this.$nextTick(() => {
        this.cardListWidth = this.$refs.courseList.clientWidth
      })
    },
    searchKeyword() {
      this.pageNumber = 0
      this.pageSize = 10
      this.courseCard.page = 0
      this.searchType = ''
    },
    showUpdata() {
      if (this.showUpdata === false) {
        this.addCancle()
      }
    }
  },
  computed: {
    size() {
      let columnNumber = Number.parseInt((window.document.body.clientWidth - 180 - 20 * 2 + 20) / 330)
      let rowNumber = Number.parseInt((window.document.body.clientHeight - 70 - 34 - 152) / 370) + 1
      return rowNumber * columnNumber
    }
  },
  methods: {
    deleteClose() {
      this.deleteVisible = false
    },
    afterDeleteClose() {
      this.deleteLoading = false
    },
    resizeList(val) {
      let size = val / 330
      if (size.toFixed(0) * 330 - val < 20) {
        this.cardListSize = Math.round(size)
      } else {
        this.cardListSize = Math.floor(size)
      }
    },
    beforeUpload(file) {
      // if (this.coverType === 0) {
      if (window.uploadUrl) {
        let forData = new FormData()
        forData.append('file', file)
        ossApi.upCover(forData).then(res => {
          this.serveLoca = res.data.res
          this.uploadCoverSuccess()
        })
      } else {
        const payload = {
          courseId: new Date().valueOf(),
          name: file.name
        }
        ossApi.getCourseCoverToken(payload).then(res => {
          const data = res.data.res
          this.actionUrl = `https://${data.host}`
          this.uploadParams.key = data.dir
          this.uploadParams.OSSAccessKeyId = data.accessId
          this.uploadParams.policy = data.policy
          this.uploadParams.Signature = data.signature
          let formData = new FormData()
          formData.append('key', this.uploadParams.key)
          formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
          formData.append('policy', this.uploadParams.policy)
          formData.append('Signature', this.uploadParams.Signature)
          formData.append('file', file)
          fetch(`https://${data.host}`, {
            method: 'POST',
            body: formData
          }).then(data => {
            this.uploadCoverSuccess()
          })
        })
      }
      // }
      return false
    },
    clearSingleStarget() {
      courseApi.delCourseTarget(this.targetCourseId, this.delTargetId).then(res => {
        this.$message.success('目标已删除')
        this.delSingle = false
        this.getAddTarget()
        this.delName = ''
      })
    },
    deltarget(data) {
      this.delTargetId = data.id
      this.delSingle = true
      this.delName = '确定删除' + data.name + '吗？'
    },
    clearStarget() {
      courseApi.clearCourseTarget(this.targetCourseId).then(res => {
        this.$message.success('目标已清空')
        this.getAddTarget()
        this.clearKnowledge = false
      })
    },
    targetsEnter(data) {
      this.targetsEnterId = data.id
    },
    cancleAdd() {
      this.targetVal = []
      this.showAddTarget = false
    },
    addCouresTargets() {
      if (this.targetVal.length === 0) {
        this.showAddTarget = false
      } else {
        let payload = {
          courseId: this.targetCourseId,
          targetIds: this.targetVal
        }
        courseApi.addCourseTarget(payload).then(res => {
          this.$message.success('目标添加成功')
          this.targetVal = []
        })
      }
    },
    targetEnter(data) {
      this.targetListId = data.id
    },
    bindTarget(data) {
      this.targetCourseId = data.courseId
      this.showAddTarget = true
      this.getTarget()
      this.getAddTarget()
    },
    getAddTarget() {
      courseApi.getCourseTarget(this.targetCourseId).then(res => {
        this.targetLists = res.res
      })
    },
    getTarget() {
      courseApi.getTarget().then(res => {
        this.targetList = res.res
      })
    },
    release(data) {
      if (data.id !== 0) {
        courseApi.categoryPublish(data.id).then(res => {
          this.getRooter()
        })
      }
    },
    delImg() {
      this.classUpdata.classUrl = ''
      this.imgName = ''
    },
    addCancle() {
      this.showUpdata = false
      this.classUpdata = {
        className: '',
        classUrl: '',
        describe: '',
        state: 0
      }
    },
    classState(data) {
      this.classUpdata.state = data
    },
    UploadImg(event, file) {},
    uploadCoverSuccess(res, file) {
      if (window.uploadUrl) {
        this.classUpdata.classUrl = this.serveLoca
      } else {
        this.classUpdata.classUrl = `${this.actionUrl}/${this.uploadParams.key}`
      }
    },
    showAll() {
      this.showbody = false
    },
    exhibition() {
      this.showbody = true
    },
    getLesson() {
      this.getCourseList()
    },
    classMoveItem(data) {
      this.moveClass = true
      this.courseId = [data.courseId]
    },
    moveCancel() {
      this.getCourseList()
      this.courseId = []
      this.categoryId = 0
      this.itemData = ''
      this.moveClass = false
    },
    classMove(data) {
      if (this.categoryId === 0 || this.courseId.length === 0) {
        this.$message.error('请选择课程分类')
        this.categoryId = 0
        this.courseId = []
      } else {
        courseApi.getGroupMove(this.categoryId, this.courseId).then(res => {
          if (res.code === 0) {
            this.getCourseList()
            this.$message.success('课程移动成功')
            this.moveClass = false
            this.courseId = []
            this.categoryId = 0
          }
        })
      }
    },
    lessonMove(data) {
      this.categoryId = data.id
    },
    allowDrop(next, prev) {
      if (next.data.parentId != prev.data.parentId) {
        return true
      }
    },
    handleDrop(data, after) {
      this.patentId = after.data.patentId
      if ((this.patentId = 0 || data.data.patentId)) {
      } else {
        const modifyCategoryDTO = {
          categoryId: data.data.id,
          name: data.data.title,
          parentId: after.data.id
        }
        courseApi.updateClass(modifyCategoryDTO).then(res => {
          if (res.code === 0) {
            this.$message.success('修改成功')
          }
        })
      }
    },
    handleClick(nodes, node, self) {
      this.defaultExpandKeys1 = [node.data.id]
    },
    updataShow(data) {
      this.classUpdata.classUrl = data.imgUrl
      if (data.imgUrl !== '' && data.imgUrl !== undefined) {
        this.updateImg = this.classUpdata.classUrl.match('[^/]+(?!.*/)')[0]
      }
      this.classUpdata.describe = data.description
      // this.imgName =
      this.classUpdata.state = data.status
      this.classUpdata.className = data.title
      this.category = data.id
      this.parentId = data.parentId
      this.isAdd = false
      this.showUpdata = true
      this.updataClass = data.title
    },
    addLesson(data) {
      this.parentId = data.id
      this.showUpdata = true
      this.isAdd = true
    },
    delLesson() {
      let data = this.treeData
      courseApi.deleCategory(this.postId).then(res => {
        if (res.code === 0) {
          this.deleteClass = false
          this.spliceById(data)
          this.$message.success('删除成功')
        }
      })
    },
    spliceById(data) {
      data.forEach((d, index) => {
        if (d.id === this.postId) {
          data.splice(
            data.findIndex(dataId => dataId.id === this.postId),
            1
          )
        } else if (d.categoryTreeVOS) {
          this.spliceById(d.categoryTreeVOS)
        }
      })
    },
    leaveAdd() {
      this.addShowId = 0
    },
    addShowIt(data) {
      this.addShowId = data.id
    },
    closeAdd() {
      this.isaddClass = false
      this.classUpdata.className = ''
      this.showItAdd = false
    },
    isadd() {
      this.showUpdata = true
      this.isaddClass = true
      this.isAdd = true
    },
    addCategory() {
      this.$refs.catForm.validate(val => {
        if (val) {
          if (this.classUpdata.classUrl === '') {
            return this.$message.error('请上传图片')
          }
          if (this.isAdd === true) {
            const addCategoryDTO = {
              name: this.classUpdata.className,
              description: this.classUpdata.describe,
              parentId: this.parentId,
              imageUrl: this.classUpdata.classUrl,
              status: Number(this.classUpdata.state)
            }
            courseApi.addCategory(addCategoryDTO).then(res => {
              if (res.code === 0) {
                this.$message.success('分类添加成功')
                this.isAdd = false
                this.showUpdata = false
                this.getRooter()
                this.addCancle()
              }
            })
          } else {
            const modifyCategoryDTO = {
              categoryId: this.category,
              name: this.classUpdata.className,
              parentId: this.parentId,
              description: this.classUpdata.describe,
              imageUrl: this.classUpdata.classUrl,
              status: this.classUpdata.state
            }
            courseApi.updateClassInfo(modifyCategoryDTO).then(res => {
              if (res.code === 0) {
                this.$message.success('修改成功')
                this.showUpdata = false
                this.isAdd = false
                this.getRooter()
                this.addCancle()
              }
            })
          }
        }
      })
      // if (this.classUpdata.className === '') {
      //   this.$message.error('请输入名称')
      // } else if (this.classUpdata.classUrl === '') {
      //   this.$message.error('请上传图片')
      // } else if (this.classUpdata.describe === '') {
      //   this.$message.error('请输入课程描述')
      // } else {
      //
      // }
    },
    getRooter() {
      courseApi.getCategoryTree(this.categoryId).then(res => {
        let arr = JSON.stringify(res.res)
        this.treeData = JSON.parse(arr.replace(/name/g, 'title'))
        this.treeData.unshift({
          id: 0,
          status: 0,
          categoryId: 0,
          title: '所有课程'
        })
        this.$nextTick(() => {
          this.$refs.treeFirst.setCurrentKey(0)
        })
        this.isaddClass = false
        this.sortTree(res.res)
      })
    },
    sortTree(data) {
      data.map(item => {
        this.treeStatus = item.status
        if (item.categoryTreeVOS) {
          this.sortTree(item.categoryTreeVOS)
        }
      })
    },
    // getAllPostById() {
    //   functionApi.getAllPostById(0).then(res => {
    //     let arr = JSON.stringify(res.res)
    //   })
    // },
    Show(data) {
      if (data === 1) {
        this.getRooter()
      }
      if (this.showbody === true) {
        if (this.isShow === true) {
          this.isShow = false
          this.isShowOut = false
        } else {
          this.isShow = true
          this.isShowOut = false
        }
      } else {
        this.isShow = false
        this.showbody = true
      }
    },
    choosePost(data) {
      this.choosePostId = data.id
      this.showBackgrong = false
      this.showName = data.title
      this.postId = data.id
      this.itCategoryId = data.id
      this.getCourseList()
    },
    cancelAdd() {
      this.addCourse = false
    },
    // handleChangeManager(id, status) {
    //   if (status === 'singleChangeManager') {
    //     this.courseManagerStatus = 'singleChangeManager'
    //     this.showManager = true
    //     this.courseId = [id]
    //     userApi.getManagerList().then(res => {
    //       this.managerList = res.res.map(item => {
    //         return item
    //       })
    //     })
    //   } else {
    //     if (this.selectIds.length === 0) {
    //       this.$message.error('您还未选择要更换管理员的课程')
    //     } else {
    //       this.showManager = true
    //       userApi.getManagerList().then(res => {
    //         this.managerList = res.res.map(item => {
    //           return item
    //         })
    //       })
    //     }
    //   }
    // },
    // chooseManager(id) {
    //   this.managerId = id
    // },
    // changeManager() {
    //   const payload = {
    //     courseIds:
    //       this.courseManagerStatus === 'singleChangeManager'
    //         ? this.courseId
    //         : this.selectIds,
    //     managerId: this.managerId
    //   }
    //   courseApi.changeManager(payload).then(res => {
    //     this.$message.success('更换成功')
    //     this.showManager = false
    //   })
    // },
    advanceSearch() {
      const payload = {
        endTime: this.searchForm.endTime.valueOf(),
        keyword: this.searchForm.name,
        page: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
        startTime: this.searchForm.beginTime.valueOf(),
        status: this.searchForm.status
      }
      this.loading = true
      courseApi
        .advanceSearch(payload)
        .then(res => {
          this.courses.data = []
          this.courses.data = res.res.data
          this.courses.total = res.res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleEdit(row) {
      this.editForm = Object.assign({}, row)
      this.edit = true
    },
    getCourseList() {
      this.loading = true
      courseApi.getClassification(this.pageNumber, this.pageSize, this.postId).then(res => {
        this.courses.data = []
        this.courses.data = res.res.data
        this.courses.total = res.res.total
        this.courseCard.data = []
        this.courseCard.data = res.res.data
        this.courseCard.total = res.res.total
        this.loading = false
      })
    },
    //根据关键词搜索，非高级，包含表格、卡片格式
    searchByKeyword(data) {
      this.searchForm.name = data
      if (this.searchForm.name === '') {
        this.getCourseList()
      } else {
        //卡片格式
        if (this.isShowCard === false) {
          this.loading = true
          courseApi.searchClass(this.pageNumber, this.pageSize, this.searchForm.name).then(res => {
            this.courses.total = res.res.total
            this.courseCard.data = []
            this.courseCard.data = res.res.data
            this.courseCard.total = res.res.total
            this.loading = false
            // this.searchForm.name = ''
          })
        } else {
          // console.log(3)
          // this.searchForm.name = ''
          this.$Loading.start()
          courseApi
            .searchClass(this.pageNumber, this.pageSize, this.searchForm.name)
            .then(res => {
              let data = res.res
              this.courseCard.total = data.total
              this.courseCard.data = data.data
              this.courseCard.page++
              // this.searchForm.name = ''
              this.courses.total = data.total
            })
            .finally(() => {
              this.$Loading.finish()
            })
        }
      }
    },
    selectCourses(data) {
      this.itemData = data
      let row = []
      data.map(item => {
        row.push(item.courseId)
      })
      this.selectIds = row
      this.courseId = row
    },
    handleDelCourses() {
      if (this.selectIds.length === 0) {
        this.$message.error('您还未选择要删除的课程')
      } else {
        this.deleteTitle = '确定要删除这些课程？'
        this.delStatus = 'multi'
        this.deleteVisible = true
      }
    },
    delClass(data) {
      // this.defaultExpandKeys1 = [data.id]
      this.postId = data.id
      this.deleteTitle = '确定要删除这些课程分类？'
      this.deleteClass = true
    },
    handleDelCourse(id) {
      this.deleteTitle = '确定要删除此课程？'
      this.deleteVisible = true
      this.delCourseIds = [id]
      this.delStatus = 'single'
    },
    delCourse() {
      this.deleteLoading = true
      if (this.postId === 0) {
        courseApi
          .delCourses(this.delStatus === 'single' ? this.delCourseIds : this.selectIds)
          .then(res => {
            if (res.code === 0) {
              if (res) {
                this.$message.success('删除成功')
                if (this.isShowCard === false) {
                  this.searchType !== 'advanceSearch' ? this.searchByKeyword('') : this.advanceSearch()
                } else {
                  this.fetchCourse(true)
                }
                this.deleteVisible = false
              } else {
                this.$message.error('删除失败')
              }
            }
          })
          .finally(() => {
            this.deleteLoading = false
          })
      } else {
        courseApi.delCategory(this.delStatus === 'single' ? this.delCourseIds : this.selectIds).then(res => {
          this.deleteLoading = false
          this.deleteVisible = false
          this.$message.success('删除成功')
          this.getCourseList()
        })
      }
    },
    toNewCourse() {
      this.$router.push('/course/newPaper')
    },
    gotoCourseDetail(row) {
      this.$router.push({
        path: `/manage/course/detail/${row.courseId}`,
        query: {
          showbody: this.isShow,
          itCategoryId: this.itCategoryId,
          choosePostId: this.choosePostId,
          defaultExpandKeys1: this.defaultExpandKeys1,
          postId: this.postId,
          showName: this.showName
        }
      })
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.handlePageChange()
    },
    changePageSize(size) {
      this.pageSize = size
      this.handlePageChange()
    },
    handlePageChange() {
      let form = this.searchForm
      if (form.name === '' && form.endTime === '' && form.beginTime === '' && form.status === '') {
        this.getCourseList()
      } else {
        this.advanceSearch()
      }
    },
    handleClickOutside(event) {
      let containsTarget = this.$refs.hiddenDropdown.$parent.$el.contains(event.target)
      if (!containsTarget) {
        this.searchVisible = false
      }
    },
    handleReachBottom() {
      let endAnimation = () => {
        clearTimeout(this.loadingTimeout)
        this.loadingText = '已经到底了╮(￣▽￣)╭'
        this.loadingTimeout = setTimeout(() => (this.loadingText = '加载中'), 2000)
        return Promise.resolve()
      }
      if (this.courseCard.total === this.courseCard.data.length) {
        return endAnimation()
      } else {
        return this.fetchCourse()
      }
    },
    //卡片显示方式，获取课程
    fetchCourse(refresh = false) {
      this.$Loading.start()
      if (refresh) {
        this.courseCard.page = 0
      }

      let promise =
        this.searchKeyword === ''
          ? courseApi.getClassification(this.courseCard.page, this.courseCard.size, this.postId)
          : courseApi.searchByKeyword({
              keyword: this.searchKeyword,
              page: {
                pageNumber: this.courseCard.page,
                pageSize: this.courseCard.size
              }
            })

      promise
        .then(res => {
          let data = res.res
          this.courseCard.total = data.total
          this.courseCard.data = refresh ? data.data : this.courseCard.data.concat(data.data)
          this.courseCard.page++
        })
        .finally(() => {
          this.$Loading.finish()
        })
    },
    back() {
      let scrollContainer = this.$refs.scroll.$el.childNodes[0]
      scrollContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    showCard() {
      this.isShowCard = !this.isShowCard
      if (this.isShowCard === true) {
        setTimeout(() => {
          let scrollContainer = this.$refs.scroll.$el.childNodes[0]
          scrollContainer.onscroll = () => {
            this.backTop = scrollContainer.scrollTop >= 100
          }
        }, 0)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';
.cation {
  color: #6a8abeff;
  font-size: 12px;
  cursor: pointer;
}
.cation :hover {
  color: #3a4e64;
}
::v-deep .ivu-upload-drag {
  width: 350px;
}
.course-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  background-color: @layout-body-background;
  position: relative;
  box-sizing: border-box;

  .func-bar {
    margin-bottom: 10px;
    width: 100%;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    height: 56px;

    .filter-btn {
      margin-right: 2%;
    }

    ::v-deep .search-container {
      display: flex;
      align-items: center;
      .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
      }
      .ivu-input:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }

      .advanced-search {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .func {
    cursor: pointer;
    background-color: #f2f3f5;
    margin-bottom: 10px;
    width: 100%;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    height: 56px;
    &:hover {
      background-color: #e4e9ef;
    }
    .filter-btn {
      margin-right: 20px;
    }

    ::v-deep .search-container {
      display: flex;
      align-items: center;
      .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
      }
      .ivu-input:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }

      .advanced-search {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .table-container {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 66px);
    ::v-deep .flex-table {
      border-bottom: 1px solid #e8eaec;

      .ivu-table-row {
        cursor: pointer;
      }

      .status-container {
        display: flex;
        align-items: center;

        .status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .operation {
        white-space: nowrap;
        cursor: pointer;
        color: @primary-color;
        transition: all @default-transition;

        &:hover {
          color: @primary-hover-color;
        }
      }

      .selection-column {
        width: 60px;
      }

      .name-column {
        width: 20%;
      }

      .description-column {
        flex: auto;
        width: 20%;
        overflow: hidden !important;
      }

      .type-column {
        width: 18%;
      }

      .status-column {
        width: 18%;
      }

      .action-column {
        width: 100px;
      }
    }

    .page {
      flex: 0 0 auto;
      padding: 20px;
      font-size: 14px;

      .page-options {
        float: left;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

::v-deep .form {
  padding: 40px;
  text-align: center;

  .search-btn {
    width: 106px;
  }
}

.scroll-container {
  background-color: @layout-body-background;
  flex: 1 1 auto;
  overflow: auto;

  ::v-deep .ivu-scroll-wrapper {
    height: 100%;

    .ivu-scroll-container {
      height: 100% !important;

      .ivu-scroll-content {
        /*卡片阴影会被遮挡，故多 2px*/
        padding-left: 2px;
      }
    }
  }

  .course-list {
    max-width: 1650px;
    display: flex;
    flex-flow: row wrap;
    /*margin-left: -20px;*/
    ::v-deep .course-card {
      margin: 0 0 20px 0;
    }
  }
  .top {
    position: fixed;
    right: 20px;
    bottom: 100px;
    background: rgba(@primary-color, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color @default-transition;
    width: 40px;
    height: 40px;
    line-height: 40px;

    &:hover {
      background: rgba(@primary-color, 0.9);
    }

    svg {
      transform: rotate(180deg);
    }
  }
}

.manager ::v-deep .ivu-modal {
  text-align: center;
  .ivu-modal-body {
    margin-top: -30px;
  }
}
.managerList {
  height: 30px;
  border-radius: 4px;
  text-align: left;
  padding: 5px 0 0 10px;
  cursor: pointer;
  &:hover {
    background-color: #e4e9efff;
  }
  &:focus {
    background-color: #e4e9efff;
    outline: none;
    font-weight: bold;
  }
}
.left {
  /*height: 780px;*/
  /*min-width: 300px;*/
  /*box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);*/
  /*margin-right: 20px;*/
  /*background-color: #ffffff;*/
  .top {
    height: 56px;
    background: #e4e9ef;
    font-size: 16px;
    color: #3a4e64;
    font-weight: 400;
    padding: 16px 23px;
    display: flex;
    align-items: center;
  }
  .tree {
    height: calc(100% - 100px);
    //padding: 15px 12px;
    text-align: left;
    overflow: auto;
    //::v-deep .el-tree-node__expand-icon {
    //  padding: 6px;
    //  position: relative;
    //  left: 25px;
    //}
  }
}
::v-deep .ivu-tree ul {
  font-size: 14px;
  color: #5e6470;
}
.show {
  cursor: pointer;
  &:hover {
    color: #6a8abeff;
  }
}
.addIt {
  cursor: pointer;
  font-size: 14px;
  color: #5e6470ff;
  background-color: #e4e9ef;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.showTable {
  margin-right: 20px;
  cursor: pointer;
}
.addClass {
  line-height: 32px;
  position: relative;
  left: 160px;
  cursor: pointer;
}
.lesson {
  white-space: nowrap;
  margin-left: 8px;
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;
  color: rgba(58, 78, 100, 1);
  opacity: 1;
}
.itClose {
  margin-right: 4px;
  width: 24px;
  height: 12px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 16px;
  opacity: 1;
}
.showLeft {
  cursor: pointer;
}
.el-tree {
  position: relative;
  cursor: default;
  background: none;
  color: #606266;
}
//::v-deep .el-tree-node__content:hover {
//  background-color: #e4e9ef;
//}
//::v-deep .el-tree-node__content {
//  min-height: 30px;
//}
/*[data-v-5c6fb3c5]:hover {*/
/*  color: #606266;*/
/*}*/
.lessons {
  white-space: nowrap;
  margin: 0 0 20px 10px;
  cursor: pointer;
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 21px;
  color: rgba(58, 78, 100, 1);
  opacity: 1;
  & :hover {
    color: rgba(58, 78, 100, 1);
  }
}
.button-container :hover {
  color: #ffffff;
}
//::v-deep.el-tree--highlight-current
//  .el-tree-node.is-current
//  > .el-tree-node__content {
//  background-color: #e1e8f2;
//}
.moveClass {
  border: 1px solid #e4e9ef;
  height: 355px;
  min-width: 443px;
  overflow: auto;
}
.sendClass {
  position: relative;
  right: 12px;
  height: 14px;
  width: 16px;
  margin-right: 5px;
  color: #dcdee2ff;
}
::v-deep .ivu-form-label-left .ivu-form-item-label {
  text-align: right;
}
.cover-item {
  height: 50px;
  display: flex;
  position: relative;
  align-items: center;
  text-align: left;
  border-top: solid 1px #e3e3e3;
  border-bottom: solid 1px #e3e3e3;
  overflow: hidden;

  & .left {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  & .middle {
    border: 1px solid red;
    flex: 1;
    padding-left: 5px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 10;
  }
  & .right {
    width: 24px;
    z-index: 10;
  }

  & .progress {
    position: absolute;
    height: 100%;
    width: 40%;
    background: rgba(104, 125, 219, 0.08);
    transition: 0.3s ease;
    .progress-text {
      position: absolute;
      height: 48px;
      line-height: 48px;
      color: rgba(151, 159, 184, 0.26);
      font-size: 40px;
      right: 8px;
    }
  }
  & .progress.finish {
    background: #47c7654f;
  }
}
.showTree {
  cursor: pointer;
  & .icon {
    height: 14px;
    width: 16px;
    margin-right: 5px;
    color: #6a8abeff;
  }
  & :hover {
    color: rgb(58, 78, 100);
  }
}
.showMore {
  cursor: pointer;
  font-size: 12px;
  color: #3a4e64ff;
  font-family: Source Han Sans CN;
  &:hover {
    color: #000000ff;
  }
}
.largeTree {
  & ::v-deep .el-tree-node__content {
    height: 100px;
    border-top: 1px solid #e6e6e6ff;
    border-bottom: 1px solid #e6e6e6ff;
  }
}
.imgUrl {
  height: 50px;
  width: 100%;
  border-top: 1px solid #eaedf1ff;
  border-bottom: 1px solid #eaedf1ff;
  padding: 5px 0;
  display: flex;
  line-height: 50px;
}
.describe {
  &::v-deep .ivu-input {
    height: 60px;
  }
}
.nodeLable {
  font-size: 14px;
  line-height: 85px;
  color: #333333ff;
  width: 300px;
}
/* 滚动条 */
::-webkit-scrollbar-thumb:horizontal {
  /*水平滚动条的样式*/
  width: 5px;
  background-color: #cccccc;
  -webkit-border-radius: 6px;
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滚动条的背景颜色*/
  -webkit-border-radius: 0; /*滚动条的圆角宽度*/
}
::-webkit-scrollbar {
  width: 10px; /*滚动条的宽度*/
  height: 8px; /*滚动条的高度*/
}
::-webkit-scrollbar-thumb:vertical {
  /*垂直滚动条的样式*/
  height: 50px;
  background-color: #999;
  -webkit-border-radius: 4px;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  /*滚动条的hover样式*/
  height: 50px;
  background-color: #9f9f9f;
  -webkit-border-radius: 4px;
}
.buttom {
  color: #bdc3cb;
  &:hover {
    color: #5e6470;
  }
}
.addTarget {
  height: 393px;
  border: 1px solid #e4e9ef;
  display: flex;
}
.top {
  color: #747475;
  font-size: 14px;
  padding: 20px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.addList {
  height: 30px;
  color: #555555;
  font-size: 13px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.target-checkbox {
  width: 100%;
  height: 44px;
  padding-left: 20px;
  line-height: 44px;
  margin-right: 0;
  &:hover {
    background-color: #ecf2fc;
  }
  ::v-deep .ivu-checkbox {
    margin-right: 8px;
  }
}
</style>
